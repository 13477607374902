import React from "react";
import Layout from "../components/layout/Layout";
import styled from "@emotion/styled";

const AboutContainer = styled.article`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  header {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    margin-bottom: 2rem;
    h3 {
      width: 100%;
      height: 10rem;
      font-weight: 700;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: var(--accent-light);
      margin: 2rem 0;
      background-color: var(--background-theme);
    }
    h4 {
      width: 100%;
      height: fit-content;
      font-weight: 700;
      font-size: 3.7rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: var(--accent-light);
      margin: 2rem 0;
      text-align: center;
      background-color: rgba(13, 74, 99, 0.7);
    }
  }
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 1rem;
    p {
      font-size: 2.3rem;
      font-weight: 400;
      strong {
        color: var(--accent-light);
        font-weight: 700;
      }
    }
    @media (min-width: 768px) {
      padding: 0 3rem;
    }
  }
  h5 {
    font-size: 3.5rem;
    strong {
      color: var(--accent-light);
    }
  }
`;

const About = () => {
  return (
    <Layout>
      <AboutContainer>
        <header>
          <h3>About</h3>
          <h4>Let me explain you a little bit about me and my motivations</h4>
        </header>
        <section>
          <p>
            When I was 8 years old I used a 486 computer for the first time.{" "}
            <strong>
              Since then, technology has always been present in my life
            </strong>{" "}
            (I have always been a gamer, almost exclusively on PC!).{" "}
          </p>
          <p>
            I got the first job of my life at 16 years old, in a cyber café,
            where I had my first contact with learning about{" "}
            <strong>networks and systems administration.</strong>
          </p>
          <p>
            Later I spent 3 years as a professional soldier in the{" "}
            <strong>communications corps of the Spanish army.</strong> I chose
            that specialty precisely because of my passion for technology.
          </p>
          <p>
            After that, it was difficult for me to determine what I wanted to
            study, and I ended up deciding on another of my passions: history.
            But still, during the time it took me to prepare for the university
            entrance exam, I did not put technology aside. I took a total of 700
            hours of training courses:{" "}
            <strong>
              Cisco CCNA Course, and Computer Systems Administration on Linux
              and Windows Server.
            </strong>
          </p>
          <p>
            Due to the effects of the economic crisis in Spain, I had to abandon
            my studies of History at the Universty of Valencia after 3 years,
            and I moved to Germany. After a long period of adaptation, I have
            managed to build a stable life ... until COVID.{" "}
          </p>
          <p>
            The Pandemic catalyzed my desire to change professions in order to
            be able to adapt to the circumstances. This time{" "}
            <strong>
              I decided to take my passion for technology out of the hobby bay
            </strong>
            , and I started creating a personal project while still maintaining
            my job. I discovered the world of <strong>SEO</strong>, learned to
            make web pages with
            <strong>Wordpress</strong> and how to optimize the SEO parameters.
            However I felt limited, since I had ignored Web Development up to
            this date.
          </p>
          <p>
            In <strong>November 2020</strong> I decided to remedy this, and I
            enrolled in the{" "}
            <strong>ILS distance study school in Hamburg</strong>, to train as a
            Web Developer. Although the course was scheduled to last 1 year,
            with almost exclusive dedication to the study I managed to obtain
            the{" "}
            <strong>
              certificate as "Geprüfter Webentwickler" (Tested Webdeveloper) in
              May 2021
            </strong>{" "}
            (5 months before planned!).{" "}
          </p>
          <p>
            At the same time, I have been doing my first projects and tests (as
            it's always said, "programming is learned by programming"), and now
            I think the time has come to make the leap to professional
            dedication as a web developer.{" "}
          </p>
        </section>

        <h5>
          <strong>Learning</strong> is one of my biggest motivations, and if
          it's <strong>technology</strong>, even better!{" "}
        </h5>
      </AboutContainer>
    </Layout>
  );
};

export default About;
